.pagination {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.pagination li {
  display: flex;
  margin: 0px 2px;
}

.break-classname > a > button > div > div{
  color: rgb(25,52,67) !important;
  text-decoration: auto !important;
}

.pagination li a button div div {
  padding: 0px !important;
}
