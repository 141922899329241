.userConfigClass {
	display: flex;

	width: 44px;
	height: 44px;

	align-items: center;
	justify-content: center;
}

.userConfigClass:hover {
	cursor: pointer;
}